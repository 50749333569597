import * as React from "react"

function Index() {
  return ( 
    <div className="Main">
      
      <h1>Website coming very soon!</h1>

    </div>
   );
}

export default Index;
